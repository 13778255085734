<template>
  <div class="organisation">
    <UserProfile />
  </div>
</template>

<script>
// @ is an alias to /src
import UserProfile from '@/modules/organisation/pages/UserProfile.vue';

export default {
  name: 'Profile',
  components: {
    UserProfile,
  },
  layout: 'AppMain',
};
</script>
